<template>
  <main>
    <lead-modal
      v-if="lead1ModalState || lead2ModalState"
      :land-code="landCode"
    />
    <new-year-modal v-if="newYearModalState" />
    <january-modal v-if="januaryModalState" />
    <!--<page-bottom-banner />-->
    <scroll-top-button />

    <app-header-school-page />
    <school-page :land-code="landCode" />
    <app-footer-school-page />
    <video-modal v-if="videoModalState" />
    <common-modals />
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LeadModal from '~/components/v2/modals/LeadModal.vue';
import VideoModal from '~/components/v2/modals/VideoModal.vue';
import ScrollTopButton from '~/components/v2/common/ScrollTopButton.vue';
import AppHeaderSchoolPage from '~/components/v2/school/AppHeaderSchoolPage.vue';
import SchoolPage from '~/components/v2/school/SchoolPageOld.vue';
import AppFooterSchoolPage from '~/components/v2/school/AppFooterSchoolPage.vue';
// import PageBottomBanner from '~/components/school/PageBottomBanner.vue';
import NewYearModal from '~/components/v2/modals/NewYearModal.vue';
import JanuaryModal from '~/components/v2/modals/JanuaryModal.vue';
import CommonModals from '@/components/v2/modals/CommonModals.vue';

export default {
  name: 'OnlineSchoolCenyPoddomen',
  components: {
    NewYearModal,
    JanuaryModal,
    // PageBottomBanner,
    VideoModal,
    ScrollTopButton,
    LeadModal,
    AppHeaderSchoolPage,
    SchoolPage,
    AppFooterSchoolPage,
    CommonModals,
  },
  layout: 'emptyLayout',

  data: () => ({
    landCode: 'new_online_school_school_ceny',
  }),

  head() {
    return {
      link: [
        { rel: 'canonical', href: 'https://school.synergy.ru/online-school' },
        { rel: 'alternate', href: 'https://school.synergy.ru/online-school-ceny' },
      ],
      meta: [{ name: 'robots', content: 'noindex' }],
      script: [{
        // eslint-disable-next-line max-len,vue/max-len
        src: 'https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=24aed672-c5f3-4e78-ba7e-eed74b860cd1&event_type=visit&product=school_synergy_ru',
        defer: true,
        type: 'text/javascript',
      }],
    };
  },

  computed: {
    ...mapGetters('modals', {
      lead1ModalState: 'lead1Modal',
      lead2ModalState: 'lead2Modal',
      videoModalState: 'videoModal',
      newYearModalState: 'newYearModal',
      januaryModalState: 'januaryModal',
    }),
  },

  mounted() {
    window.addEventListener('keydown', this.keydownHnd);
    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize({ target: window });

    this.removeSalebot();
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keydownHnd);
    window.removeEventListener('resize', this.setWindowSize);
  },

  methods: {
    ...mapActions({
      setWindowSize: 'setWindowSize',
    }),
    ...mapActions('modals', {
      closeAllModals: 'closeAll',
    }),

    keydownHnd(event) {
      if (event.code === 'Escape') {
        this.closeAllModals();
      }
    },

    /**
     * setInterval, кнопка появляется с задержкой
     * count чтобы в бесконечность не ушёл
     */
    removeSalebot() {
      let count = 0;

      const interval = setInterval(() => {
        count += 1;
        const botBtn = document.querySelector('.salebot_circle_trigger');

        if (botBtn) {
          botBtn.remove();
          clearInterval(interval);
        }

        if (count > 30) {
          clearInterval(interval);
        }
      }, 200);
    },
  },
};
</script>

<style scoped lang="scss">
main * {
  font-family: 'TildaSans', sans-serif;
}

::v-deep button {
  font-family: 'TildaSans', sans-serif;
}

::v-deep input {
  font-family: 'TildaSans', sans-serif !important;
}

// артефакты от старых страниц
::v-deep section {
  margin-bottom: 0;

  h2,
  h3 {
    margin: 0;
  }
}
</style>
